<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <vx-card >
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <!-- {{clientsData}} -->
            <vs-table id="table-with-loading" class="vs-con-loading__container" v-model="selected2" multiple stripe pagination max-items="50" noDataText="" search :data="clientsData">
              <template slot="header">
                <!-- ACTION - DROPDOWN -->
                <!-- <vs-dropdown vs-trigger-click class="cursor-pointer">

                  <div class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32">
                    <span class="mr-2 leading-none">Actions</span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                  </div>

                  <vs-dropdown-menu>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Supprimer</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>CSV</span>
                      </span>
                    </vs-dropdown-item>

                  </vs-dropdown-menu>
                </vs-dropdown> -->
                <vs-button class="mb-4 mr-5 md:mb-0" @click="$router.push('/apps/client/client-add')">Nouveau client</vs-button>
                <template v-if="selectUserOfficine(activeUserInfos.IdOfficine) && selectUserOfficine(activeUserInfos.IdOfficine).OfficinePrincipale === true">
                  <vs-select placeholder="Officine" autocomplete class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="idOfficine" @change="CurrentOfficineSelectClient()">
                    <vs-select-item  class="w-full" :key="index" :value="item._id" :text="item.NomOffic" v-for="(item,index) in officines" />
                  </vs-select>
                </template>

                <vue-simple-suggest
                  pattern="\w+"
                  v-model="model"
                  :list="getClientList"
                  :max-suggestions="100"
                  :min-length="3"
                  :debounce="200"
                  :filter-by-query="false"
                  :prevent-submit="true"
                  :controls="{
                    selectionUp: [38, 33],
                    selectionDown: [40, 34],
                    select: [13, 36],
                    hideList: [27, 35]
                  }"
                  :mode="mode"
                  :nullable-select="true"
                  ref="suggestComponent"
                  placeholder="Search information..."
                  value-attribute="id"
                  display-attribute="fullname"
                  @select="onSuggestSelect">

                  <div class="g">
                    <input type="text" placeholder="Recherche Client: Nom prénoms">
                  </div>

                  <template slot="misc-item-above" slot-scope="{ suggestions, query }">
                    <div class="misc-item">
                      <span>Vous recherchez '{{ query }}'.</span>
                    </div>

                    <template v-if="suggestions.length > 0">
                      <div class="misc-item">
                        <span>{{ suggestions.length }} suggestions sont affichées...</span>
                      </div>
                      <hr>
                    </template>

                    <div class="misc-item" v-else-if="!loading">
                      <span>Aucun résultat</span>
                    </div>
                  </template>

                  <div slot="suggestion-item" slot-scope="scope" :title="`${scope.suggestion.Nom} ${scope.suggestion.Prenoms}`">
                    <div class="text">
                      <span v-html="boldenSuggestion(scope)"></span>
                    </div>
                  </div>

                  <div class="misc-item" slot="misc-item-below" slot-scope="{}" v-if="loading">
                    <span>Chargement...</span>
                  </div>
                </vue-simple-suggest>
                <!-- <vs-input placeholder="Recherche: Koffi"></vs-input> -->
              </template>
              <template slot="thead">

                <vs-th sort-key="_id">
                  N°
                </vs-th>
                <vs-th sort-key="Civilite">
                  Civilite
                </vs-th>
                <vs-th sort-key="Nom">
                  Nom
                </vs-th>
                <vs-th sort-key="Prenoms">
                  Prenoms
                </vs-th>
                <vs-th sort-key="Profession">
                  Profession
                </vs-th>
                <vs-th sort-key="Adresse.email">
                  Email
                </vs-th>
                <vs-th sort-key="Adresse.mobile">
                  Mobile
                </vs-th>
                <vs-th sort-key="Assure">
                  Assure
                </vs-th>
                <vs-th>
                  Actions
                </vs-th>
              </template>

              <template slot-scope="{data}">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td>
                    <span @dblclick="ClientDetailShowForm(tr)">
                      {{indextr + 1}}
                    </span>
                  </vs-td>

                  <vs-td :data="tr.Civilite ? tr.Civilite : null">
                    <span @dblclick="ClientDetailShowForm(tr)">
                      {{tr.Civilite ? tr.Civilite : ''}}
                    </span>
                  </vs-td>

                  <vs-td :data="tr.Nom ? tr.Nom : null">
                    <span @dblclick="ClientDetailShowForm(tr)">
                      {{tr.Nom ? tr.Nom : ''}}
                    </span>
                  </vs-td>

                  <vs-td :data="tr.Prenoms ? tr.Prenoms : null">
                    <span @dblclick="ClientDetailShowForm(tr)">
                      {{tr.Prenoms ? tr.Prenoms : ''}}
                    </span>
                  </vs-td>

                  <vs-td :data="tr.Profession ? tr.Profession : null">
                    <span @dblclick="ClientDetailShowForm(tr)">
                      {{tr.Profession ? tr.Profession : ''}}
                    </span>
                  </vs-td>

                  <vs-td :data="tr.Adresse ? tr.Adresse.email : ''">
                    <span @dblclick="ClientDetailShowForm(tr)">
                      {{tr.Adresse ? tr.Adresse.email : ''}}
                    </span>
                  </vs-td>

                  <vs-td :data="tr.Adresse ? tr.Adresse.mobile : null">
                    <span @dblclick="ClientDetailShowForm(tr)">
                      {{tr.Adresse ? tr.Adresse.mobile : ''}}
                    </span>
                  </vs-td>

                  <vs-td :data="tr.Assure">

                    <template v-if="tr.Assure == true">
                      <vs-chip transparent color="success">
                        Oui
                      </vs-chip>

                    </template>
                    <template v-else>
                      <vs-chip transparent color="danger">
                        Non
                      </vs-chip>
                    </template>
                  </vs-td>

                  <vs-td>
                    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
                      <feather-icon icon="EyeIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="ClientDetailShowForm(tr)" />
                      <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="updateClientShowForm(tr)" />
                      <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord(tr)" />
                    </div>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>

          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import VueSimpleSuggest from 'vue-simple-suggest'
import 'vue-simple-suggest/dist/styles.css'
import axios from '@/axios.js'
export default {
  data () {
    return {
      model: null,
      mode: 'input',
      loading: false,
      chosen: '',
      selected: null,
      selected2:[],
      clientId: '',
      clients: [],
      currentClientDeletedId: '',
      idOfficine: null,
      activeUserInfos: JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')) : null
    }
  },
  components: {
    VueSimpleSuggest
  },
  computed: {
    validateForm () {
      return !this.errors.any() && this.libelle !== ''
    },

    clientsData () {
      return this.$store.state.client.clients
    },
    officines () {
      return this.$store.state.officine.officines
    }
  },

  methods: {
    getClientList (inputValue) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/client-full-search/${this.idOfficine}/${inputValue}`)
          .then((response) => {
            /* const fields = ['Nom', 'Prenoms'] */
            const result = response.data
            response.data.forEach((part, index) => {
              console.log('part', part)
              response.data[index].fullname = `${part.Nom} ${part.Prenoms}`
            })
            console.log('result', result)
            resolve(result)
            /* resolve(response) */
          })
          .catch((err) => {
            reject(err)
          })

      })
    },
    onSuggestSelect (suggest) {
      this.selected = suggest
      this.$store.commit('client/ADD_SEARCH_CLIENT', suggest)
    },
    boldenSuggestion (scope) {
      if (!scope) return scope
      const { suggestion, query } = scope
      const result = this.$refs.suggestComponent.displayProperty(suggestion)
      if (!query) return result
      const texts = query.split(/[\s-_/\\|.]/gm).filter(t => !!t) || ['']
      return result.replace(new RegExp(`(.*?)(${  texts.join('|')  })(.*?)`, 'gi'), '$1<b>$2</b>$3')
    },
    updateClientShowForm (data) {
      this.$router.push(`/apps/client/client-edit/${data._id}`).catch((err) => {
        console.log(err)
        this.$vs.notify({
          title: 'error',
          text: `${err}`,
          iconPack: 'feather',
          icon: 'icon-error',
          color: 'danger'
        })
      })
    },
    ClientDetailShowForm (data) {
      this.$router.push(`/apps/client/client-view/${data._id}`).catch((err) => {
        console.log(err)
        this.$vs.notify({
          title: 'error',
          text: `${err}`,
          iconPack: 'feather',
          icon: 'icon-error',
          color: 'danger'
        })
      })
    },
    selectUserOfficine (id) {
      return this.$store.getters['officine/getOfficineById'](id)
    },
    confirmDeleteRecord (data) {
      this.currentClientDeletedId = data._id
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmé la Suppression',
        text: `Vous allez supprimer le client  "${data.Nom} ${data.Prenoms}"`,
        accept: this.deleteRecord,
        acceptText: 'Supprimer'
      })
    },
    deleteRecord () {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('client/removeClient', this.currentClientDeletedId)
        .then(() => {
          this.showDeleteSuccess()
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    showDeleteSuccess () {
      this.$vs.notify({
        color: 'danger',
        title: 'Client supprimé',
        text: 'Le client selectionné a bien été supprimée'
      })
    },
    getClientsGroup () {
      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('client/getClientsGroup')
        .then(() => {
          this.$vs.loading.close()
        })
        .catch((err) => {
          console.log(err)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    getOfficines () {
      this.$store.dispatch('officine/getOfficines')
        .then(() => {

        })
        .catch((err) => {
          console.log(err)
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    CurrentOfficineSelectClient () {
      this.getClientsOfficine(this.idOfficine)
    },
    activeUserInfo () {
      this.idOfficine = this.activeUserInfos.IdOfficine

      this.getClientsOfficine(this.idOfficine)
      /* if (this.$store.state.client.clients.length === 0) {
      } */
    },
    getClientsOfficine (id) {
      this.$vs.loading()
      this.$store.dispatch('client/getClientsOfficine', id)
        .then(() => {
          this.$vs.loading.close()
        })
        .catch((err) => {
          console.log(err)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    }
  },
  created () {
    this.activeUserInfo()
    if (this.$store.state.officine.officines.length === 0) this.getOfficines()
    /* this.getClientsGroup() */
  }
}
</script>

